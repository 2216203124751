//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import PlanForm from '@/modules/plan/components/plan-form.vue'
import PlanFormStep1 from '@/modules/plan/components/plan-form-step1.vue'
import PlanFormStep2 from '@/modules/plan/components/plan-form-step2.vue'
import PlanFormStep3 from '@/modules/plan/components/plan-form-step3.vue'


import { getLanguageCode } from '@/i18n';
// import { i18n } from '@/i18n';

export default {
  name: 'app-plan-form-page',
  props: ['id'],

  components: {
    [PlanForm.name]: PlanForm,
    [PlanFormStep1.name]: PlanFormStep1,
    [PlanFormStep2.name]: PlanFormStep2,
    [PlanFormStep3.name]: PlanFormStep3,


  },

  data() {
    return {
      language: getLanguageCode(),
      model: null,
      step: 1
     };
  },
  computed: {
    ...mapGetters({
      record: 'plan/form/record',
      findLoading: 'plan/form/findLoading',
      saveLoading: 'plan/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      citiesRows: 'city/list/rows',  
      loadingCities: 'city/list/loading', 
      placesRows: 'place/list/rows',  
      loadingPlaces: 'place/list/loading', 
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isEditing() {
      return !!this.id;
    },
  },

  // async created() {
  //   if (this.isEditing) {
  //     await this.doFind(this.id);
  //   } else {
  //     await this.doNew();
  //   }
  //   this.model = formSchema.initialValues(this.record);
  //   this.model.type = this.fields.type.options[0].value;
  //   this.model.name = this.model.name || { en: undefined, ar: undefined }
  //   this.model.description = this.model.description || { en: undefined, ar: undefined }
  // },
  // async mounted() {
  //   await this.doFetchCities()
  // },

  methods: {
    ...mapActions({
      doFind: 'plan/form/doFind',
      doNew: 'plan/form/doNew',
      doUpdate: 'plan/form/doUpdate',
      doCreate: 'plan/form/doCreate',
      doFetchCities: 'city/list/doFetch',
      doFetchPlaces: 'place/list/doFetch',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },

    prev() { this.step-- },
    next() { 
      if (this.step == 2 && this.duration != 'one') {
        for (const property in this.programList) {
          if (this.programList[property].length) {
            this.list2.push(...this.programList[property])
          }
        }
        this.step++ 
        return
      } 
      
      if (this.step == 3) {
        return
      } 
      this.step++ 
    },


    // doReset() {
    //   this.model = formSchema.initialValues(this.record);
    // },
    // async doSubmit() {
    //   if (this.step != 3) {
    //     if (this.step == 1) await this.doFetchPlaces({ filter: { cityIds: this.selected_cities } }) 
    //     if (this.step == 2 && this.duration == 'one') {
    //       this.programList[1] = this.list2
    //       // this.model.programs = this.list2.map(el => {
    //       //   return {
    //       //     placeId: el.id,
    //       //     selectedImage: undefined,
    //       //     averageTime: undefined,
    //       //   }
    //       // })
    //     }
    //     return this.next();
    //   }

    //   // let progs = Object.keys(this.programList).map(key => {
    //   //   return {
    //   //     name: key,
    //   //     places: this.programList[key].map(item => {
    //   //       return {
    //   //         id: item['id'],
    //   //         selectedImage: item['selectedImage'],
    //   //         averageTime: item['averageTime'],
    //   //       }
    //   //     })
    //   //   }
    //   // }) 
    //   // console.log('Progs => ', progs);
    //   const { id, ...values } = formSchema.cast(this.model);
    //   values.numberOfDays = parseInt(this.model.numberOfDays);
    //   values.programs = Object.keys(this.programList).map(key => {
    //     return {
    //       name: key,
    //       places: this.programList[key].map(item => {
    //         return {
    //           id: item['id'],
    //           selectedImage: item['selectedImage'] ? item['selectedImage'] : '',
    //           averageTime: item['averageTime'] ? item['averageTime'] : 0,
    //         }
    //       })
    //     }
    //   })
    //   if (this.isEditing) {
    //     return await this.doUpdate({
    //       id,
    //       values,
    //     });
    //   } else {
    //     let record = await this.doCreate(values);
    //     return record;
    //   }
    // },
  },
};
