//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';
import { getLanguageCode } from '@/i18n';

export default {
  name: 'app-select-place-image-modal',
  // props: ['visible', 'images'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    }, 
  },

  data() {
    return {
      language: getLanguageCode(),
      dataImages: [
        // {
        //   id: '1',
        //   src: 'https://unsplash.it/200?random',
        // }, 
        // {
        //   id: '2',
        //   src: 'https://unsplash.it/200?random',
        // }, 
        // {
        //   id: '3',
        //   src: 'https://unsplash.it/200?random',
        // },
        // {
        //   id: '4',
        //   src: 'https://unsplash.it/200?random',
        // }, 
        // {
        //   id: '5',
        //   src: 'https://unsplash.it/200?random',
        // }, 
      ],
    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    selectImageDimensions() {
      if (this.is_screen_xs) {
        return '50px'
      } else {
        return '105px'
      }
    },
  },

  methods: {
    ...mapActions({

    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    doClose() {
      this.$emit('close')
    },
    onSelectImage(val) {
      this.$emit('onSelectImage', val)
    }
  },
};
