//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from "vuedraggable";
import { mapGetters } from 'vuex'
import i18n from '@/vueI18n'
let id = 1;
export default {
  props: ['numberOfDays','list2'],
  name: "transition-example2",
  display: "Two list header slot",
  order: 14,
  components: {
    draggable
  },
  data() {
    return {
      list: [
        { name: "John 1", id: 0 },
        { name: "Joao 2", id: 1 },
        { name: "Jean 3", id: 2 }
      ],
    //   list2: [{ name: "Jonny 4", id: 3 }, { name: "Guisepe 5", id: 4 }]
    // list2:[],
     days: [
        this.i18n('entities.plan.days.dayOne'), 
        this.i18n('entities.plan.days.dayTwo'), 
        this.i18n('entities.plan.days.dayThree'), 
        this.i18n('entities.plan.days.dayFour'), 
        this.i18n('entities.plan.days.dayFive'), 
        this.i18n('entities.plan.days.daySix'), 
        this.i18n('entities.plan.days.daySeven')
      ],
      drag: false
    };
  },
  computed:{
    ...mapGetters({
      placesRows: 'place/list/rows',  
      is_screen_xs: 'layout/is_screen_xs'
    }),
    language(){
      return i18n.locale
    }  
  },
  methods: {
    add: function() {
      this.list.push({ name: "Juan " + id, id: id++ });
    },
    replace: function() {
      this.list = [{ name: "Edgard", id: id++ }];
    },
    add2: function() {
      this.list2.push({ name: "Juan " + id, id: id++ });
    },
    replace2: function() {
      this.list2 = [{ name: "Edgard", id: id++ }];
    },
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
  },
  created(){
       for (let index = 0; index < this.numberOfDays; index++) {
        this.list2[index+1] = [];
      }

  },
};
